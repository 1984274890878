import { AcessoRapidoService } from './../../services/acesso-rapido.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, ViewEncapsulation, AfterViewChecked, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { StateService } from '../../services/stateService';
import { Router, ActivatedRoute } from '@angular/router';
import { AppShortcutsFacade } from '../../facades/app-shortcuts.facade';
import { Observable, Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { ItensListaMenu, ItemBusca } from '../../utils/ItemsListaMenu';
import CustomerService from '../../services/customer.service';

@Component({
  selector: 'app-lista-menu-mobile',
  templateUrl: './lista-menu-mobile.component.html',
  styleUrls: ['./lista-menu-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None, 
})
export class ListaMenuMobileComponent implements OnInit, AfterViewInit, OnDestroy {
  public isListDesign$: Observable<boolean>;
  public subscription: Subscription;
  public modules = {
    financeiro: false,
    gestaoDePessoal: false,
    condominio: false,
    reservasEFacilidades: false,
    comunicacao: false,
  };

  public itemListaPesquisa: ItemBusca[] = ItensListaMenu;
  public busca: string = '';
  public itensBusca: ItemBusca[] = [];
  public resultadoBusca: ItemBusca[] = [];
  public customer$ = this.customerService.getCustomerObservable();
  
  
  @Input() ehGestaoTotal: boolean;
  @Input() ehContaPool: boolean;
  @Input() possuiPerfilSindico: boolean;
  @Input() possuiPerfilLocatario: boolean;
  @Input() possuiPerfilProprietario: boolean;
  @Input() possuiPerfilGestorSindico: boolean;
  @Input() possuiPerfilCondomino: boolean;
  @Input() possuiPerfilSubSindico: boolean;
  @Input() possuiPerfilConselheiro: boolean;
  
  constructor(
    public appShortcuts: AppShortcutsFacade,
    public stateService: StateService,
    private router: Router,
    private activedRoute: ActivatedRoute,
    public platform: Platform,
    private customerService: CustomerService,
    public acessoRapidoService: AcessoRapidoService
    ) { 
      activedRoute.params.subscribe(val => {
        this.isListDesign$ = this.appShortcuts.isListDesignObservable();
        this.ngOnInit();
      });
    }
    
  ngOnInit() { 
    this.possuiPerfilSindico = this.stateService.possuiPerfilSindico();
    this.possuiPerfilLocatario = this.stateService.possuiPerfilLocatario();
    this.possuiPerfilProprietario = this.stateService.possuiPerfilProprietario();
    this.possuiPerfilGestorSindico = this.stateService.possuiPerfilGestor() || this.stateService.possuiPerfilSindico();

    // this.seachInput.addEventListener()
    this.itensBusca = this.acessoRapidoService.filtraListaBusca();
    
  }
  
  ngAfterViewInit(): void {
    this.subscription = this.platform.backButton.subscribeWithPriority(999999, ((processNextHandler) => {
      if(this.hasNoModuleSelected)
        processNextHandler();
      else
        this.deselectAllModules();
    }) as any);
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  possuiPermissaoAcesso(idFuncionalidade: number) {
    return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }

  ehCondominioPrime() : boolean{
    var condominioLogado = this.stateService.getCondominioLogado();
    if(!condominioLogado) return false;
    return condominioLogado.prime === 1
  }

  validaExibicaoContrato(){
    var gerencial = this.stateService.possuiAcessoCentralAtendimento();
    var condominio = this.stateService.getCondominioLogado();
    if(gerencial && condominio && !this.ehGestaoTotal) {
      if(condominio && (condominio.agencia == "19" || condominio.agencia == "20")) {
        return true;
      } else return false;
    } 
    else if(condominio && !this.ehGestaoTotal)
    {
      return (condominio.agencia == "19" || condominio.agencia == "20") && (this.stateService.possuiAcessoFuncionalidadeGeral(866) || this.stateService.possuiAcessoFuncionalidadeGeral(289));
    }
    else return this.stateService.possuiAcessoFuncionalidadeGeral(866) || this.stateService.possuiAcessoFuncionalidadeGeral(289);
  }

  possuiPermissaoAcessoValidacaoCentralAtendimento(idFuncionalidade: number){
    if(this.stateService.visaoCentralAtendimento())
      return this.stateService.possuiAcessoFuncionalidadeGeral(idFuncionalidade);
    else
      return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }

  handleClickComodidades() {
    this.stateService.abrirLinkDeComodidadesDeAcordoComOPerfil();
  }

  selectModule(name: string) {
    Object.keys(this.modules).forEach(module => this.modules[module] = false);

    this.modules[name] = true;
  }

  isModuleSelected(name: string) {
    return this.modules[name];
  }

  hasNoModuleSelected() {
    const keys = Object.keys(this.modules);

    for(let i = 0; i < keys.length; i++) {
      if(this.modules[keys[i]]) {
        return false;
      }
    }

    return true;
  }

  deselectAllModules() {
    const keys = Object.keys(this.modules);

    for(let i = 0; i < keys.length; i++) {
      this.modules[keys[i]] = false;
    }
  }

  verificaContaPool() {
    if (this.stateService.getCondominioLogado()) {
      return this.stateService.getCondominioLogado().ehContaPool;
    }
    return false;
  }

  redirectSeguros() {
    this.router.navigate(['/seguros']);
  }

  atualizarBusca() {
    this.resultadoBusca = this.itensBusca.filter(item => item?.titulo.toUpperCase().indexOf(this.busca.toUpperCase().trim()) !== -1 || item?.subItem?.toUpperCase().startsWith(this.busca.toUpperCase().trim()));
    this.resultadoBusca = this.resultadoBusca.filter(item => item);
  }

  getTipoImg(img: string): number {
    if(img?.includes('fa-')) {
      return 1;
    }
    
    if(img?.includes('span')) {
      return 2;
    }

    if(img?.includes('../')) {
      return 3;
    }
    
    if(img?.includes('<svg')) {
      return 4;
    }

  }

  handleClickBusca(item?: string) {
    if(item) {
      if(item === 'comodidades') {
        this.handleClickComodidades()
      }

      if(item === 'alugar/vender') {
        this.stateService.redirectAlugarVenderPorPerfil();
      }

      if(item === 'seguros') {
        this.redirectSeguros();
      }

      if(item === 'fornecedores') {
        this.stateService.openExternalLink("https://apsa.com.br/fornecedores/");
      }
    }
  }
}
