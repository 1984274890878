import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import { Router } from "@angular/router";
import { distinctUntilChanged, Subscription } from "rxjs";
import { LoginService } from "src/app/modules/login/services/login.service";
import { AcessoRapidoService } from "../../services/acesso-rapido.service";
import { StateService } from "../../services/stateService";
import { ItemBusca } from "../../utils/ItemsListaMenu";
import { CondominioService } from "../../services/condominio.service";
 
@Component({
    selector: 'app-favoritos',
    templateUrl: './favoritos.component.html',
    styleUrls: ['./favoritos.component.scss']
})
export class FavoritosComponent implements OnInit, OnDestroy, OnChanges {

    // @HostListener('window:resize', ['$event']) onResize(event: Event) {}
    @Input() trocouCondominio
    public favoritos: ItemBusca[] = [];
    public itensBusca: ItemBusca[] = [];
    public displayModal: boolean = false;

    public subscription: Subscription;
    public swiperUpdated: boolean = true;

    constructor(
        public stateService: StateService, 
        public acessoRapidoService: AcessoRapidoService, 
        private loginService: LoginService, private router: Router,
        public condominioService: CondominioService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.trocouCondominio.currentValue) {
            this.stateService.getCondominioLogado();
            setTimeout(() => {
                this.itensBusca = this.acessoRapidoService.filtraListaBusca();
                this.updateSwiper();
                this.getFavoritos();
            }, 500)
        }
    }

    ngOnInit(): void {
        this.itensBusca = this.acessoRapidoService.filtraListaBusca();
        this.getFavoritos();
        
        this.subscription = AcessoRapidoService.trocaFavoritos.pipe(distinctUntilChanged()).subscribe(_ => {
            this.getFavoritos();
            this.updateSwiper();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getTipoImg(img: string): number {
        if(img?.includes('fa-')) {
            return 1;
        }

        if(img?.includes('span')) {
            return 2;
        }

        if(img?.includes('../')) {
            return 3;
        }
    }

    getSlidesPerView(): number {
        if(window.innerWidth > 1200) {
            return 5;
        }
        else if(window.innerWidth > 1000) {
            return 3.5;
        }
        else if(window.innerWidth <= 800 && window.innerWidth >= 400) {
            return 2.5;
        }
        else {
            return 2.2;
        }
    }

    openModalEdicao() {
        AcessoRapidoService.abrirModalEdicaoFavoritos.next(true);
        // let modal = document.getElementsByClassName('modal-edicao-favoritos')[0];
        // if(modal) modal.classList.add('__display');
    }

    getFavoritos() {
        this.acessoRapidoService.getFavoritos().subscribe({
            next: res => {
                if(res && res.length > 0) {
                    this.favoritos = [];
                    for(let favorito of res) {
                        let itemLista = this.itensBusca.find(item => item?.rota ? item.rota === favorito.rota : item.click === favorito.rota);
                        if(itemLista) this.favoritos.push(itemLista);
                    }
                    this.favoritos = [...new Set(this.favoritos)]
                    this.getEquivalentes(res);
                    this.updateSwiper();
                } else if(this.favoritos.length === 0) {
                    this.getFuncionalidadesFrequentes();
                }
            },
            error: err => this.getFuncionalidadesFrequentes()
        })
    }

    setFavoritos(listaRotas: string[]) {
        this.acessoRapidoService.setFavoritos(listaRotas).subscribe({
            next: res =>  AcessoRapidoService.trocaFavoritos.next(this.favoritos),
            error: err => {
                console.log('erro ao setar favoritos')
            } 
        })
    }

    getFuncionalidadesFrequentes() {
        this.loginService.getFuncionalidadesFrequentes().subscribe({
            next: features => {
                if(features?.length > 0) {
                    for(let i = 0; i < features.length; i++) {
                        if(i < 5) {
                            let itemLista = this.itensBusca.find(item => item?.rota === features[i]?.rota || item?.rota?.startsWith('https://') && features[i]?.rota?.startsWith('https://') );
                            if(itemLista) this.favoritos.push(itemLista);
                        }
                    }
                    this.updateSwiper();
                    let payloadFavoritos: string[] = [];
                    for(let favorito of this.favoritos) payloadFavoritos.push(favorito.rota);
                    this.setFavoritos(payloadFavoritos);
                } else {
                    this.setFavoritosEstaticos();
                }
            },
            error: err => this.setFavoritosEstaticos()
        });
    }

    setFavoritosEstaticos() {
        if(this.stateService.possuiPerfilSindico() || this.stateService.possuiPerfilSubSindico() || this.stateService.possuiPerfilConselheiro()) {
            if(this.stateService.possuiAcessoFuncionalidade(218)) 
                this.favoritos.push(this.itensBusca.find(item => item.rota === '/extrato'));
            if(this.stateService.possuiPerfilProprietario()) 
                this.favoritos.push(this.itensBusca.find(item => item.rota ==='/extrato/proprietario'));
            if(this.stateService.possuiAcessoFuncionalidade(215) || this.stateService.possuiAcessoFuncionalidade(216) || this.stateService.possuiAcessoFuncionalidade(217)) 
                this.favoritos.push(this.itensBusca.find(item => item.rota=== '/prestacao-contas'));
            if(this.stateService.possuiAcessoFuncionalidade(306))
                this.favoritos.push(this.itensBusca.find(item => item.rota === '/envio-documento'));
            if(this.stateService.possuiAcessoFuncionalidade(885))
                this.favoritos.push(this.itensBusca.find(item => item.rota === '/cotas-inadimplentes'));
        } else if(this.stateService.possuiApenasPerfilCondomino()) {
            if(this.stateService.possuiAcessoFuncionalidade(219) || this.stateService.possuiAcessoFuncionalidade(220))
                this.favoritos.push(this.itensBusca.find(item => item.rota === '/cotas'));

            this.favoritos.push(this.itensBusca.find(item => item.rota === '/extrato/sindico'));

            if(this.stateService.possuiAcessoFuncionalidade(810))
                this.favoritos.push(this.itensBusca.find(item => item.rota === '/documento'));
            if(this.stateService.possuiAcessoFuncionalidade(215) || this.stateService.possuiAcessoFuncionalidade(216) || this.stateService.possuiAcessoFuncionalidade(217))
                this.favoritos.push(this.itensBusca.find(item => item.rota === '/prestacao-contas'));   
        }
        this.updateSwiper();
        let listaRotasFavoritos: string[] = [];
        for(let favorito of this.favoritos) { listaRotasFavoritos.push(favorito?.rota) }
        this.setFavoritos(listaRotasFavoritos);
    }

    handleClick(favorito: ItemBusca) {
        switch(favorito?.click) {
            case 'comodidades': this.stateService.abrirLinkDeComodidadesDeAcordoComOPerfil();
                break;
            case 'alugar/vender': this.stateService.redirectAlugarVenderPorPerfil();
                break;
            case 'fornecedores': this.stateService.openExternalLink("https://apsa.com.br/fornecedores/");
        }

        if(favorito?.rota && favorito?.rota.startsWith('http')) {
            this.stateService.openExternalLink(favorito.rota);
            return;
        }

        if(favorito?.rota) this.router.navigate([favorito.rota]);
    }

    updateSwiper() {
        this.swiperUpdated = false;
        setTimeout(() => this.swiperUpdated = true, 200);
    }

    getEquivalentes(favoritos: any[]) {
        if(favoritos.find(item => (item?.rota as string)?.startsWith('/ocorrencia') && !this.favoritos.find(item => item?.rota?.startsWith('/ocorrencia')))) {
            this.favoritos.push(this.itensBusca.find(item => (item?.rota as string)?.startsWith('/ocorrencia')));
        }

        if(favoritos.find(item => (item?.rota as string)?.startsWith('/mural') && !this.favoritos.find(item => item?.rota?.startsWith('/mural')))) {
            this.favoritos.push(this.itensBusca.find(item => (item?.rota as string)?.startsWith('/mural')));
        }

        if(favoritos.find(item => (item?.rota as string)?.startsWith('/reserva') && !this.favoritos.find(item => item?.rota?.startsWith('/reserva')))) {
            this.favoritos.push(this.itensBusca.find(item => (item?.rota as string)?.startsWith('/reserva') && (item?.rota as string) !== '/reserva-configuracao'));
        }
    }

}